<template>
  <div id="forgotPassword">
    <vue-progress-bar class="vueProgressBar"></vue-progress-bar>
    <div class="container">
      <div class="row">
        <div class="col-lg-7 col-md-7 col-12">
          <div class="login-logo mt-5">
            <a href="https://cvscreen.com.au/" target="blank"
              ><img
                src="../../assets/img/logo.png"
                class=""
                alt="Vehicle Solutions"
                style="height: 100px"
            /></a>
          </div>

          <img
            src="../../assets/img/sgi-registrer.png"
            class="img-fluid img-bottom"
            alt="Vehicle Solutions"
            width="500px"
          />
        </div>

        <div class="col-lg-5 col-md-5 col-12">
          <div class="login-page bg-body">
            <div class="login-box">
              <div class="card">
                <div class="card-body login-card-body">
                  <p class="login-box-msg">
                    You forgot your password? Here you can easily retrieve a new
                    password.
                  </p>

                  <form v-on:submit.prevent="fnForgotPassword">
                    <div class="input-group mb-3">
                      <input
                        type="email"
                        class="form-control"
                        placeholder="Email"
                        v-model="user.email"
                      />
                      <div class="input-group-append">
                        <div class="input-group-text">
                          <span class="fas fa-envelope"></span>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <button type="submit" class="btn btn-primary btn-block">
                          Request new password
                        </button>
                      </div>
                    </div>
                  </form>

                  <p class="mt-3 mb-1">
                    <router-link to="/login" class="small">Login</router-link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as authService from "../../services/auth.service";
import * as messageService from "../../services/message.service";

export default {
  data() {
    return {
      user: {
        email: "",
      },
      errors: {},
    };
  },
  methods: {
    fnForgotPassword: async function () {
      try {
        // Progress bar
        this.$Progress.start();

        const response = await authService.forgotPassword(this.user);
        this.errors = {};

        if (response.data.status_code === 200) {
          this.$router.push({ name: "forgotPasswordWelcomeMessage" });
        }

        // Progress bar
        this.$Progress.finish();
      } catch (error) {
        switch (error.response.status) {
          case 401:
            this.errors = error.response.data.errors;
            messageService.fnSweetAlertErrorToast(
              "Request new password",
              error.response.data.message
            );
            break;
          case 404:
            this.errors = error.response.data.errors;
            messageService.fnSweetAlertErrorToast(
              "Request new password",
              error.response.data.message
            );
            break;

          case 422:
            this.errors = error.response.data.errors;
            messageService.fnSweetAlertErrorToast(
              "Request new password",
              error.response.data.message
            );
            break;
          case 500:
            messageService.fnSweetAlertErrorToast(
              "Request new password",
              error.response.data.message
            );
            break;
          default:
            messageService.fnSweetAlertErrorToast(
              "Request new password",
              "Something wrong, please try again!"
            );
            break;
        }

        // Progress fail (error)
        this.$Progress.fail();
      }
    },
  },
};
</script>